.react-toast-notifications__container {
    width: 100vw;
}

kbd abbr[title] {
    text-decoration: none !important;
}

.ap-search-container:focus-within .ap-keyboard-container {
    display: none !important;
}
.loader div {
    animation-duration: 0.5s;
}

.loader div:first-child {
    animation-delay: 0.1s;
}

.loader div:nth-child(2) {
    animation-delay: 0.3s;
}

.loader div:nth-child(3) {
    animation-delay: 0.6s;
}
